@tailwind base;
@tailwind components;
@tailwind utilities;

body {
}

.gradient-bg {
    background-image: url('../images/baby-blue-gradient.svg');
    background-repeat: no-repeat;
    background-size: cover; /* or 100% 100% depending on your needs */
}
